import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { FormGroup, Overlay } from 'react-bootstrap';

import TableColumn from '@core/models/TableColumn';

import { Button, Checkbox, Dropdown, Form, Icon, Loader, MenuItem, Popover } from '@components/dmp';

const DROPDOWN_KEYS = {
  displayAll: 'Display all',
  custom: 'Custom',
};

const MultilineColPopover = ({
  col,
  container,
  saveMultilineVar,
  colValue,
  multilineValueOptions,
  multilineValueLabels,
}) => {
  const initialValues = colValue.split('\n');
  const displayOnSelected = initialValues.length === multilineValueOptions?.length;
  const [saving, setSaving] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedValues, setSelectedValues] = useState(initialValues);
  const [selectionDropdown, setSelectionDropdown] = useState(
    displayOnSelected ? DROPDOWN_KEYS.displayAll : DROPDOWN_KEYS.custom
  );
  const [allInstances, setAllInstances] = useState(false);

  const valuesList = _.uniq(multilineValueOptions || initialValues);
  const allowSettingAddressInstances = col.allowSettingAddressInstances(valuesList);

  const isTable = !!col.table;

  const showPopover = (e) => {
    e.stopPropagation();
    setShow(true);
  };

  const hidePopover = (e) => {
    e.stopPropagation();
    setShow(false);
  };

  const save = async () => {
    setSaving(true);
    saveMultilineVar(selectedValues.join('\n'), allInstances);
    setSaving(false);
    setShow(false);
  };

  const selectMultilineValue = (val) => {
    if (!selectedValues.includes(val)) {
      const updatedSelection = isTable ? [...selectedValues, val] : [val];
      setSelectedValues(updatedSelection);
      if (valuesList.every((val) => updatedSelection.includes(val))) {
        setSelectionDropdown(DROPDOWN_KEYS.displayAll);
      }
    } else {
      setSelectedValues(selectedValues.filter((value) => value !== val));
      setSelectionDropdown(DROPDOWN_KEYS.custom);
    }
  };

  const onDropdownSelect = (key) => {
    if (key === DROPDOWN_KEYS.displayAll) {
      setSelectedValues(valuesList);
      setSelectionDropdown(DROPDOWN_KEYS.displayAll);
    } else if (key === DROPDOWN_KEYS.custom) {
      setSelectedValues([]);
      setSelectionDropdown(DROPDOWN_KEYS.custom);
    }
  };

  const textRef = useRef();

  const updateDisabled =
    selectedValues.length === 0 ||
    (initialValues.length === selectedValues.length && initialValues.every((val) => selectedValues.includes(val)));

  return (
    <>
      <div
        ref={textRef}
        onClick={showPopover}
        className={'variable-multi-line-text'}
        data-variable={col.displayName}
        onMouseDown={(e) => e.stopPropagation()}
        onMouseUp={(e) => e.stopPropagation()}
      >
        {valuesList.map((val, idx) => {
          return (
            <span className={!initialValues.includes(val) ? 'strike-through-value' : ''} key={idx}>
              {col.formatValue(val)}
              {multilineValueLabels && multilineValueLabels[val] && (
                <span className="multi-line-label">{` (${multilineValueLabels[val]})`}</span>
              )}
            </span>
          );
        })}
        <span className="option-selection-label">
          {selectionDropdown}
          <span className="spacer" />
          <Icon name="chevronSmall" />
        </span>
      </div>
      <Overlay
        container={container}
        onHide={hidePopover}
        placement="bottom"
        rootClose
        show={show}
        target={textRef.current}
      >
        <Popover
          id={`${col.id}-pop`}
          onClick={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
          onMouseUp={(e) => e.stopPropagation()}
          title={col.displayName}
          className="popover-variable-view"
          closeBtn={show}
          onHide={hidePopover}
        >
          <Dropdown
            size="small"
            block
            id={`${col.id}-multiline-selectionDropdown`}
            onSelect={onDropdownSelect}
            title={selectionDropdown}
            className="multiline-selection-dropdown"
          >
            <MenuItem eventKey={DROPDOWN_KEYS.displayAll}>Display all</MenuItem>
            <MenuItem eventKey={DROPDOWN_KEYS.custom}>Custom</MenuItem>
          </Dropdown>

          <Form>
            <FormGroup>
              <div className={`variable-multiple-value form-control`}>
                {valuesList.map((val, idx) => {
                  return (
                    <Checkbox
                      key={val}
                      id={`chk-${idx}`}
                      checked={selectedValues.includes(val)}
                      onChange={() => selectMultilineValue(val)}
                      disabled={selectionDropdown === DROPDOWN_KEYS.displayAll}
                    >
                      {val}
                      {multilineValueLabels && multilineValueLabels[val] && (
                        <span className="multi-line-label">{` (${multilineValueLabels[val]})`}</span>
                      )}
                    </Checkbox>
                  );
                })}
              </div>
            </FormGroup>
          </Form>
          <div className="actions">
            {saving && <Loader />}
            {!(allowSettingAddressInstances && !saving) && <div className="spacer" />}
            {allowSettingAddressInstances && !saving && (
              <>
                <Checkbox
                  checked={allInstances}
                  onChange={() => setAllInstances(!allInstances)}
                  placement="right"
                  className="set-all-addresses"
                  disabled={updateDisabled}
                >
                  Set all instances
                </Checkbox>
                <div className="spacer" />
              </>
            )}
            <>
              <Button className="cancel" size="small" onClick={hidePopover}>
                Cancel
              </Button>
              <Button className="save" disabled={updateDisabled} size="small" onClick={save} dmpStyle="primary">
                Update
              </Button>
            </>
          </div>
        </Popover>
      </Overlay>
    </>
  );
};

MultilineColPopover.propTypes = {
  colValue: PropTypes.string.isRequired,
  col: PropTypes.instanceOf(TableColumn).isRequired,
  container: PropTypes.object,
  saveMultilineVar: PropTypes.func.isRequired,
  multilineValueOptions: PropTypes.array,
  multilineValueLabels: PropTypes.object,
};

export default MultilineColPopover;
